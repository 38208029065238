import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./BoothPrintDetails.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link, useLocation, useParams} from 'react-router-dom';
import {useState, useEffect} from 'react';
import Image from 'react-bootstrap/Image';
import Category from "../category";

type CategoryItem = {
  text: string;
  img_src: string;
  description: string;
}

type Catergory = {
  title: string;
  id: string;
  products: CategoryItem[];
}

function CategoryDetailsBooth() {
  const [data,setData]=useState<Catergory[] | undefined>(undefined);

  const location = useLocation();
  //const propsData = location.state as any;
  

  useEffect(()=>{
    const element = document.getElementById("service");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
  },[])


  return (

<div>
      <section className="features">
         <Container>

        <div className="section-title">
          <h2>Photo Templates</h2>
          <p>Templates from our guests available in color, glam or your choice of filters</p>
        </div>
        <Row className="justify-content-center align-items-center">

          <Col md={3} className="pt-4 picture-box">
              <img src="/images/phototemplates/11.jpg" className="img-fluid" alt=""></img>
          </Col>
          <Col md={3} className="pt-4">
          <h3>Classic Postcard</h3>
              <p className="fst-italic">
              Use your favourite fonts to highlight your special day. 
              </p>
          </Col>
          <Col md={3} className="pt-4 picture-box">
              <img src="/images/phototemplates/7.jpg" className="img-fluid" alt=""></img>
          </Col>
          <Col md={3} className="pt-4">
          <h3>Simple Border</h3>
              <p className="fst-italic">
                A Simple Border with fonts of your choice.
              </p>
          </Col>
</Row>
        <Row className="justify-content-center align-items-center">

          <Col md={3} className="pt-4 picture-box">
              <img src="/images/phototemplates/8.jpg" className="img-fluid" alt=""></img>
          </Col>
          <Col md={3} className="pt-4 picture-box">
              <img src="/images/phototemplates/13.jpg" className="img-fluid" alt=""></img>
          </Col>
          <Col md={2} className="pt-4">
          <h3>Elegent Postcard</h3>
              <p className="fst-italic">
                Crisp clean border with a simple design.
              </p>
          </Col>
          </Row>
        <Row className="justify-content-center align-items-center">

          <Col md={2} className="pt-4 picture-box ">
              <img src="/images/phototemplates/10.jpg" className="img-fluid" alt=""></img>
          </Col>
          <Col md={2} className="pt-4">
          <h3>Photo Strip</h3>
              <p className="fst-italic">
                4 up photos to capture multiple shots.   
              </p>
          </Col>
        </Row>









        </Container>
    </section>

</div>
  );
}



export default CategoryDetailsBooth;
